@import 'partials/variables';

.agents-page-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .agents-page-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
    }
}

.title-agent{
    padding-bottom: 10px;
}

.agents-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:40px;
    margin-bottom: 5px;
}

.plus-button-root{
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.plus-button,.plus-button:hover,.plus-button:focus{
    width:50px;
    height: 50px;
}

.creditLimitText{
    padding-right: 10px;
}