
.book-button{
    background-color: #44C309!important;
    border-color: #44C309!important;
    color: #FFFFFF!important;
}

.block-button{
    background-color: #1B6BE3!important;
    border-color: #1B6BE3!important;
    color: white!important;
}

.phone-block{
    background-color: #FFA000!important;
    border-color: #FFA000!important;
    color: white!important;
}

.payment-link-btn{
    background-color: #009DCE!important;
    border-color: #009DCE!important;
    color: white!important;
}

.disableInput{
    background: white!important;
    border-width: 0px!important;
    border-color: white!important;
    color: black!important;
}

.seatNumberInput{
    max-width: 60px!important;
    background: white!important;
    border-width: 0px!important;
    border-color: white!important;
    color: black!important;
}

.fareInput{
    max-width: 50px!important;
    background: white!important;
    border-width: 0px!important;
    border-color: white!important;
    color: black!important;
    padding: 0px!important;
    border-radius: 6px!important;
}

.passengerNameInput{
    background-color: #FFFFFF!important;
    height: 40px!important;
    box-sizing: border-box!important;
    border-radius: 6px!important;
}

.ageInput{
    background-color: #FFFFFF!important;
    height: 40px!important;
    box-sizing: border-box!important;
    border-radius: 6px!important;
    max-width: 80px;
}

.genderInput {
    background-color: #FFFFFF!important;
    height: 40px!important;
    box-sizing: border-box!important;
    border-radius: 6px!important;
    max-width: 100px;
}

.passenger-block > .ant-row > .ant-col > .ant-form-item > .ant-form-item-control > .ant-form-item-explain-error{
    // height: 0;
    display: none;
}

.passenger-block > .ant-row > .ant-col > .ant-form-item {
    margin-bottom: 0px!important;
}

.passengerNameLabel{
    width: 200px !important;
    text-align: center;
}

.boarding-point-select-style , .boarding-point-select-style:focus,.boarding-point-select-style:hover{
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 250px;
    color: black;
 
}

.drop-point-select-style , .drop-point-select-style:focus,.drop-point-select-style:hover{
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 250px;
    color: black;   
}
.genderLabel {
    width: 140px !important;
    text-align: center;
}

.ageLabel{
    width: 80px !important;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.fareLabel{
    width: 100px !important;
    text-align: center;
}

.phone-number-block {
    margin-top:10px;
    @media only screen and (min-width: 768px) {
        margin-top:20px;
    }
}

.passenger-details-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:100px;
}

.confirm-booking-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:100px;
}

.copy-same-name-txt{
    margin-top: 5px;
    margin-bottom: 5px;
    @media only screen and (min-width: 768px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.three-dots-button{
    margin: 0px!important;
    padding: 0px!important;
}

.passed-point{
    background-color: #FF6767!important;
    color: #FFFFFF!important;
}

.point-even{
    // Note ant last select item styles breaking
    // margin-top: 2px;
    background-color: #E7F1FF;
}

.point-odd{
    // Note ant last select item styles breaking
    // margin-top: 2px;
}

.ant-select-item-option-content {
    white-space: break-spaces;
    word-break: break-word;
  }

  .passengerName{
    width: 90px!important;
    word-wrap: break-word;         /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;  
  }