

@import './../../styles/partials/variables';


.justShowSeatLayout{
    justify-content: center;
    align-items: center;
    align-content: center;
}

.disabledDiv {
    pointer-events: none;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px); 
    -ms-filter: blur(2px);
    filter: blur(2px);
}

.setLocationOnMapBlock{
    align-items: center;
    justify-content: center;
    display: flex;
    align-content: center;
    margin-bottom: 20px;
}

.setLocationOnMapButton , .setLocationOnMapButton:hover , .setLocationOnMapButton:active , .setLocationOnMapButton:focus{
    background-color: #2BB193;
    border: #2BB193;
}

.trip-create-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .trip-create-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
        .trip-create-form-div{
            @media only screen and (max-width: 768px) {
                max-width: 80vw;
            }
        }
    }
}

.trip-root-form{
    @media only screen and (min-width: 768px) {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 10px;
    }
}

.side-nav {
    height : fit-content;
    background: white;
    margin-right: 10px;
    border-radius: 15px;
    // border: 2px solid #C4C4C4; 
    max-width: 430px;
}

.trip-divider{
    background-color: #E1E1E1;
}

.flex-end-div{
    display: flex;
    flex-direction: row-reverse;
}

.side-verify-nav {
    height : fit-content;
    background: white;
    margin-right: 10px;
    border-radius: 15px;
    // border: 2px solid #C4C4C4; 
    max-width: 400px;
}

.verify-root-div {
    padding: 10px;
}

.marker-style {
    color: rgb(5, 0, 0);
    font-size: medium;
    margin-top: 20px;
}

.create-trip-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:80px;
    margin-bottom: 5px;
}

.create-trip-bottom-ribbon {
   
}

.create-trip-ribbon-text{
    margin: 20px;
}

.dynamic-delete-button {
    padding: 5px;
}

.dynamic-edit-button{
    padding: 5px;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background: '#01B468';
    border: '#01B468';
}

.ant-steps-item-active .ant-steps-item-icon{
    background: '#FFCF26';
    border: '#FFCF26'
}

.create-trip-root{
    
}

.draggable-root{
    margin-bottom: 60px;
}

.city-select-root{
    flex-direction: row;
    display: flex;
    align-items: center;
}

.stop-select-root{
    flex-direction: row;
    display: flex;
    align-items: center;
}

.address-select-stop{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items:center;
    align-content: center;
    align-self: center;
    justify-content: space-between;
}

.address-select-form-item{
    margin-bottom: 5px;
}

.edit-delete-block{
    display: flex;
    flex-direction: row;
}

.create-new-stop-block{
    border: 1px solid #C4C4C4;
    padding: 5px;
}

.fetch-latlng-ok-button{
    display: flex;
    justify-content: flex-end;
}

.add-button{
    display: flex;
    justify-content: center;
}

.city-number{
    padding: 4px;
}

.stop-number{
    padding: 4px;
}

.stop-root{
    display: flex;
    flex-direction: row;
}

.address-form-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.ok-button-address , .ok-button-address:focus , .ok-button-address:hover{
    margin: 0px!important;
    height: 30px!important;
    padding: 2px!important;
    bottom: 54px!important;
}