
.ticketLogs{
    overflow-y: auto;
}

.travellerLogs{
    overflow-y: auto;
}

.traveller-block{
    border: 1px solid #B0B0B0;
    box-sizing: border-box;
    padding: 10px;
}

.ticket-passenger-block{
    border: 1px solid #B0B0B0;
    box-sizing: border-box;
}

.seat-number-select{
    min-width: 70px!important;
    max-width: 70px!important;
}

.seat-gender-select{
    min-width: 60px!important;
    max-width: 60px!important;
}

.boarding-point-select{
    min-width: 100px!important;
}

.boarding-points-table  , .passenger-table{ 
    .ant-table-cell{
        padding: 5px!important; 
    }
}

.update-ticket-underline{
    border-bottom: 4px solid var(--defaultButtonColor);
    width:100px;
}

.passenger-input{
    width:250px;
    @media only screen and (max-width: 768px) {
        width: 100px!important;
    }
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
}

.phone-no-input-style{
    width:250px;
    @media only screen and (max-width: 768px) {
        width: 120px!important;
    }
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
}

.email-id-input-style{
    width:250px;
    @media only screen and (max-width: 768px) {
        width: 200px!important;
    }
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
}

.modify-cancel-btn{
    @media only screen and (max-width: 768px) {
        height: 60px!important;
    }
}


.print-ticket-popup-btn{
    @media only screen and (max-width: 768px) {
        height: 60px!important;
    }
}


.update-ticket-popup-btn{
    @media only screen and (max-width: 768px) {
        height: 60px!important;
    }
}

.confirm-status-txt{
    color: #2BB193;
    font-weight: 700;
}


.cancel-status-txt{
    color: #ED716C;
    font-weight: 700;
}

.ticket-logo-divider {
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);

    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 2px 0;
}

/* Add this to your CSS file */
.custom-radio-fix .ant-radio-inner::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }