
.new-transactions-button{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .new-transactions-text{
        padding-right: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}

.agent-form-root{
    display: flex;
    flex-direction: column;
    .agent-form-inside{
        display: flex;
    }
    .new-transactions-text{
        padding-bottom: 10px;
    }
    .agent-form{
        border-radius: 6px;
        border:1px solid #C4C4C4;
        padding: 10px;
    }
    padding-bottom: 10px;
}