


.edit-fare-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:70px;
    margin-bottom: 5px;
}

.edit-fare-table{
    .ant-table{
        .ant-table-thead > tr > th , .ant-table-tbody > tr > td{
            @media only screen and (max-width: 768px) {
                // background-color: #fafafa!important;
                // border-color: #fafafa!important;
                margin: 2px;
                padding: 2px;
            }
            background-color: #FFFFFF!important;
            border-color: #FFFFFF!important;
        }
    }
}

.edit-fare-table-root{
    @media only screen and (max-width: 768px) {
        overflow-y: none;
        overflow-x: scroll;
    }
}


.edit-fare-modal{
    width: auto!important;
    .ant-table table{
        width: auto!important;
    }
    .ant-modal-content{
        display: inherit;
    }
}

.close-button{
    margin-left: 250px;
}

.buttons-block{
  display: flex;
  @media only screen and (max-width: 768px) {
            padding-bottom: 10px;
    }
}

.update-fare-button , .update-fare-button:focus , .update-fare-button:active , .update-fare-button:hover {
    background: #01B468!important;
    border-color: #01B468!important;
}


.copy-fare-button , .copy-fare-button:focus , .copy-fare-button:active , .copy-fare-button:hover {
    background: #1057B9!important;
    border-color: #1057B9!important;
}

.get-fare-button , .get-fare-button:focus , .get-fare-button:active , .get-fare-button:hover {
    background: #FF6767!important;
    border-color: #FF6767!important;
}

.edit-fare-input{
    max-width:60px;
    @media only screen and (max-width: 768px) {
        // font-size:10px;
    }
}

.fare-input-error{
    border-color: red!important;
    color: red;
}