
.admin-block{
    background-color: #0342B4;
    border-color: #0342B4;
    color: white!important;
}

.admin-block:hover{
    background-color: #0342B4;
    border-color: #0342B4;
    color: white!important;
}

.admin-block:focus{
    background-color: #0342B4;
    border-color: #0342B4;
    color: white!important;
}
