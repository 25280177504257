

.revenueTripDescription{
    font-weight: bold;
}

.revenueTripDate{
    font-weight: bold;
}

.revenueBlock{
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.revenueValue{
    margin-left: 20px;
    font-weight: bold;
    font-size: 20px;
    border: 4px solid #D9D9D9;
    border-radius: 10px;
    padding: 5px;
}

.revenueHeader{
    color: #D9D9D9;
}

.bookedValue{
    font-weight: bold;
}