.set-gender-btn{
    background-color: #662CE1;
    border-color: #662CE1;
    color: white!important;
}

.set-gender-btn:hover{
    background-color: #662CE1;
    border-color: #662CE1;
    color: white!important;
}

.set-gender-btn:focus{
    background-color: #662CE1;
    border-color: #662CE1;
    color: white!important;
}   

.set-gender-btn:active{
    background-color: #662CE1;
    border-color: #662CE1;
    color: white!important;
}
