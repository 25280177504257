@import 'partials/variables';

.agents-transactions-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:120px;
    margin-bottom: 5px;
}

.title-agent-transactions{
    padding-bottom: 10px;
}