
.city-col{
    width: 100px;
    overflow-wrap: break-word;
    @media only screen and (max-width: 768px) {
        width: 70px;
    }
}

.time-col{
    width: 100px;
    @media only screen and (max-width: 768px) {
        width: 90px;
    }
}

.day-col{
    width: 30px;
}

.is-main-stop{
    width: 60px;
    margin-left: 15px;
    @media only screen and (max-width: 768px) {
        width: 30px;
    }
}

.trip-close-point{
    width: 40px;
}

.verify-check-box{
    .ant-checkbox-inner:not(.ant-checkbox-checked){
        border: 1px solid #817D7D;
    }
}

.time-incre-decre , .time-incre-decre:hover , .time-incre-decre:focus{
    background-color: #2BB193;
    border-color: #2BB193;

}

.time-incre-decre:disabled, .time-incre-decre[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.edit-time-btn{

}

.next-time-btn,.next-time-btn:hover,.next-time-btn:focus{
    background-color: #FF9100;
    border-color: #FF9100;
}

.update-time-btn,.update-time-btn:hover,.update-time-btn:focus{
    background-color: #FF9100;
    border-color: #FF9100;
}

.cancel-time-btn ,.cancel-time-btn:hover ,.cancel-time-btn:focus {
    background-color: #000000;
    color: white;
    border-color: #000000;
}

.final-summary-root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.final-summary-header{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: baseline;
}
.final-summary-sub-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.refresh-button{
    color: #000000;
    bottom: 5px;
    left:10px;
}


.remove-button , .remove-button:focus , .remove-button:hover{
    color: #817D7D;
    bottom: 5px;
    left:5px;
}

.list-item-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list-item-sub-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.final-summary-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.route-list-item{
    border: 2px solid #ECE9E9;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    width: 250px;
}

.route-list-main{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-header{
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
}