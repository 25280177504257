// Base mixin to handle all antd component states
@mixin override-antd-states($bg-color, $border-color, $text-color) {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active,
    &:focus-visible,
    &:focus-within {
      background-color: $bg-color !important;
      border-color: $border-color !important;
      color: $text-color !important;
    }
  }
  
  // For components that need specific hover states
//   @mixin override-antd-hover-states($hover-bg, $hover-border, $hover-text) {
//     &:hover {
//       background-color: $hover-bg !important;
//       border-color: $hover-border !important;
//       color: $hover-text !important;
//     }
//   }
  
  // Example usage for buttons
  .ant-btn {
    // @include override-antd-states(var(--defaultButtonColor), var(--defaultButtonColor), var(--defaultButtonText));
    
    // Remove default antd focus outline
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  
  // For select components
  .ant-select {
    // .ant-select-selector {
    //   @include override-antd-states(#FFFFFF, var(--defaultButtonColor), inherit);
    // }
    
    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none !important;
      }
    }
  }
  
  // For input components
  .ant-input {
    // @include override-antd-states(#FFFFFF, var(--defaultButtonColor), inherit);
    
    &-focused {
      box-shadow: none !important;
    }
  }
  
  // For checkboxes
  .ant-checkbox {
    &-checked {
      .ant-checkbox-inner {
        @include override-antd-states(var(--defaultButtonColor), var(--defaultButtonColor), #FFFFFF);
      }
    }
    
    &-indeterminate {
      .ant-checkbox-inner {
        @include override-antd-states(#FFFFFF, var(--defaultButtonColor), inherit);
        
        &:after {
          width: 8px;
          height: 8px;
          background-color: var(--defaultButtonColor);
          border: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    
    &:hover, &:focus, &:active, &:focus-visible, &:focus-within, &::selection, &:active {
      .ant-checkbox-inner {
        border-color: var(--defaultButtonColor) !important;
      }
    }
  }
  
  // For radio buttons
  .ant-radio-wrapper {
    .ant-radio-checked {
      .ant-radio-inner {
        @include override-antd-states(#FFFFFF, var(--defaultButtonColor), inherit);
        
        &:after {
          background-color: var(--defaultButtonColor);
        }
      }
    }
  }
  
  // For tabs
  .ant-tabs {
    .ant-tabs-tab {
      @include override-antd-states(transparent, transparent, inherit);
      
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--defaultButtonColor) !important;
        }
      }
    }
    
    .ant-tabs-ink-bar {
      background-color: var(--defaultButtonColor);
    }
  }

//   .ant-btn,.ant-btn:hover,.ant-btn:focus{
//     color: #FFFFFF;
//     height: 40px;
//     background: var(--defaultButtonColor);
//     border-radius: 6px; 
//     border-color:var(--defaultButtonColor);
//     font-weight: bold;
//     justify-content: center;
//     color : var(--defaultButtonText);
// }

.ant-tabs-tab-active > .ant-tabs-tab-btn , 
.ant-tabs-tab-btn :hover , 
.ant-tabs-tab-btn :focus{
    color: var(--defaultButtonColor)!important;
    font-weight: bold!important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn{
    background: #FFFFFF!important;   
}

.ant-tabs-ink-bar{
    background : var(--defaultButtonColor)!important;
}

