@import 'partials/variables';

.report-root{
    background-color: var(--secondaryColor);
    padding: 10px;
    padding-left: 20px;
    width: fit-content;
    width: 100%;
    // margin-top: var(--header-height);
}

.background-report-root{
    background-color: var(--secondaryColor);
    padding: 10px;
}

.report-inner-div{
    background-color: white;
    padding:10px;
    border-radius: 6px;
}

.seats-column-in-all-agents{
    width:250px;
    word-break: break-all;
}
.ticket-search-report-root {
    background-color: #F4F6F9;
    padding: 10px;
    padding-left: 20px;
    width: fit-content;
    min-width:100vw;
}
.ticket-search-inner-div{
    padding:10px;
    border-radius: 6px;
}

.ticket-search-table {
    .ant-table-thead .ant-table-cell {
        background-color: #E7E9EE !important;
    }
    .ant-table-thead .ant-table-tbody .ant-table-summary {
        border-radius: 10px;
    }
    .ant-table-thead > tr > th {
        border-bottom: 2px solid #F4F6F9;
        border-top: 0px solid black;
        border-left: 0px solid black;
    }
    .ant-table-thead > tr > th:last-child {
        border-right: 0px solid black;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 2px solid #F4F6F9;
        border-left: 0px solid black;
    }
    .ant-table-tbody > tr > td:last-child {
        border-right: 0px solid black;
    }
    .ant-table-summary > tr > td {
        border-bottom: 2px solid #F4F6F9;
        border-left: 0px solid black;
        border-right: 0px solid black !important;
    }
}