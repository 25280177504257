
@import 'partials/variables';

.view-ticket-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .view-ticket-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
        .view-ticket-form{
            @media only screen and (max-width: 768px) {
                max-width: 80vw;
            }
        }
    }
}

.percentageInput{
    padding-left: 10px;
    width: 100px;
}

.pnrInput{
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    @media only screen and (max-width: 768px) {
        max-width: 250px;
    }
}

.view-cancel-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:80px;
    margin-bottom: 5px;   
}
