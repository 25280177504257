@import 'partials/variables';

.staff-cancel-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .staff-cancel-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
        .staff-cancel-form-div{
            @media only screen and (max-width: 768px) {
                max-width: 80vw;
            }
        }
    }
}

.staff-register-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:80px;
    margin-bottom: 5px;
}



.staff-form-root{
    display: flex;
    flex-direction: column;
    .staff-form-inside{
        display: flex;
    }
    .new-transactions-text{
        padding-bottom: 10px;
    }
    .staff-form{
        border-radius: 6px;
        border:1px solid #C4C4C4;
        padding: 10px;
    }
    padding-bottom: 10px;
}