
.donut-block {
    background-color: #FFFFFF;
    margin: 10px;
    padding-left: 20px;
    padding-top: 20px;
    border-radius: 10px;
    align-items: center;
    box-shadow: 0 6px 6px -6px #95A6BF;
    .donutdate-service-block{
        text-align: center;
        margin-right: 45px;
        border: 1px solid #FF9100!important;
        border-radius: 5px;
    }
}

.progress-bar-container{
    .line{
        width: 1px;
        height: 8px;
        position: absolute;
        background: red!important;
        // left: 100px;
        bottom: 0px;
    }
}
.donut-no-arrow {
    display: none;
}

.donut-arrow {
    position: relative;
    border-left: 20px solid rgba(0, 0, 0, 0);
    border-right: 20px solid rgba(0, 0, 0, 0);
    border-bottom: 20px solid rgba(0, 0, 0, 0);
    border-top: 20px solid #FFFFFF;
    display: inline-flex;
    height: 15px;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,.5));
    margin-top: -2px;
}

.first-arrow{
    margin-left: 14%;
}
.second-arrow{
    margin-left: 43%;
}
.third-arrow{
    margin-left: 68%;
}

.donutdate-block{
    text-align: center;
    margin-right: 45px;
    font-weight: bold;
}

.donutdate-block-big {
    text-align: center;
    margin-right: 45px;
    font-size: 18px;
    font-weight: bold;
}

.donut-extention-block{
    background-color: #FFFFFF;
    margin: -43px 20px 20px 20px;
    padding-left: 20px;
    padding-top: 30px;
    border-radius: 0px 0px 10px 10px;
}

.daily-trip-summary{
    background-color: #FFFFFF;   
    padding: 10px;
    margin: 10px 10px 0px 10px;
    border-radius: 5px;
    min-width: 250px;
    font-weight:bolder;
    font-size: larger;
}

.daily-trip-summary-extention{
    background-color: #FFFFFF;   
    padding: 10px;
    margin: -10px 10px 10px 10px;
    border-radius: 0px 0px 5px 5px;
    min-width: 250px;
    max-width: 400px;
    @media only screen and (min-width: 768px) {
        max-width: 600px;
    }
    @media only screen and (max-width: 468px) {
        padding-bottom: 50px;
    }
}

.tickets-booked-today{
    background-color: #FFFFFF;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    min-width: 300px;
    max-width: 400px;
    font-weight:bolder;
    font-size: larger;
    .click-card {
        cursor: pointer!important;
    }
}

.donut-common,.donut-common > canvas{
    cursor: pointer!important;
}

.square-box{
    height: 10px;
    width: 10px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    top:5px;
}

.seat-text{
    text-align: center;
    margin-right: 5px;
    font-size:15px;
    font-weight:500;
}

.agent-value{
    margin-right: 10px;
    font-size:15px;
    font-weight:500;
}

.seat-value{
    margin-right: 10px;
    font-size:15px;
    font-weight:500;
}

.agentName{
    margin-right: 5px;
    font-size:15px;
    font-weight:500;
}

.agentsTitle{
    font-weight: bold;
}

.trip-summary-each-trip{
    // background-color: yellow;
    margin: 10px;
}

.trip-summary-each-trip > #new_id_text > #new_id {
    border-radius: 20px;
}

div#new_id_text {
    text-align: center !important;
}

svg#new_id {
    border-radius: 5px;
}

// https://getcssscan.com/css-box-shadow-examples
.trip-summary-each-trip {
    padding: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.maxRevenue{
    float: right;
}

.donut-second{
    margin-left: -50px;
}

.donut-third{
    margin-left: -50px;
}

.revenue-for-week{
    background-color: #FFFFFF;   
    padding: 10px;
    margin: 10px 10px 0px 10px;
    border-radius: 5px;   
    min-width: 250px;
    font-weight:bolder;
    font-size: larger;
}

.donut-root-block{
    @media only screen and (max-width: 768px) {
        min-width: 100vw;
    }
}

.revenue-for-week , .daily-trip-summary{
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        min-width: 95vw;
    }
}

.revenue-for-week-extention-block{
    background-color: #FFFFFF;   
    padding: 10px;
    margin: -10px 10px 10px 10px;
    border-radius: 5px;   
    min-width: 250px;
}

.occupancyTitle {
    font-weight: bold;
    text-align: center;
    font-size: large;
}

.dateHeader{
    font-weight: bold;
    text-align: center;
}

.revenueText{
    font-weight: bold;
    color: #7B7B7B;
}

.revenueValueDashBoard{
    font-weight: bold;
    font-size: 30px;
}

.totalSeatsTxt{
    font-weight: bold;
}

.totalSeatsValue{
    font-weight: bold;
}

.no-of-trips-block{
    font-size:15px;
    font-weight:500;
}

.trip-description{
    font-size:15px;
    font-weight:500;
}

.revenue-total{
    font-size: 25px;
    font-weight: bold;
}

.online-agents{
    font-weight: bold;
}



.progress-bar-container {
    height: 8px;
    position:relative; /* relative here */
    background-color: #E5E8EC;
  }
  
  .progress-bar-indicator {
    height: 100%;
    border-radius: 25px;
     /* this will do the magic */
    -webkit-mask:linear-gradient(#fff 0 0);
            mask:linear-gradient(#fff 0 0);
  }
  .progress-bar-indicator::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    // background-image: linear-gradient(to right, #E50027, #EFBD40,#81D653); /* your gradient here */
  }
  .first-color {
    background-color: #D2382E;
}
.second-color {
    background-color: #EC9D33;
}
.third-color {
    background-color: #FBE454;
}
.fourth-color {
    background-color: #CAE950;
}
.fifth-color {
    background-color: #6FCB42;
}


.monthly-occupancy-calender{
    width: 266px;

    .ant-picker-calendar .ant-picker-panel{
        border: 0px!important;
    }
    
    .site-extended-calendar{
        border: 0px!important;   
    }
    
    .ant-picker-cell{
        padding: 2px;
    }

    .ant-picker-content th{
        font-weight: bold;
    }
    
    .previous-month-date{
        color: #FFFFFF;
        background-color: #FFFFFF;
    }

    .next-month-date{
        display: none;
    }
  
    .ant-picker-cell:not(.ant-picker-cell-in-view) {
        pointer-events: none;
        // display: none;
    }

    .ant-picker-cell {
        z-index: 1;
    }

    .current-date{
        height: 100%;
        width: 100%;
        display: flex;
        // justify-content: center;
        align-items: center;
        border-radius: 4px;
        z-index: 100;
        flex-direction: column; 

    }

    .next-month-date{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        flex-direction: column;
    }
    .ant-row-middle{
        justify-content: center;
    }
}