

.bus-delay-btn{
    min-width: 180px;
}

.delay-time{
    max-width: 180px!important;
}

.delay-email-txt-area{
    width: 300px;
}