@import 'partials/variables';

.login-form-root{
    display: flex;
    justify-content: center;
    align-items: center;
    background:var(--primaryColor);
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    padding: 20px;
    .login-form-secondary{
        background-color: var(--white);
        padding: 20px;
        border-radius: 15px;
        .heading-div{
            line-height: 36px;
            text-align: center;
            margin: 20px;
        }
        .login-form-button{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
