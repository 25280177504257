@import './../../styles/partials/variables';

.menu-heading-title {
    background-color: var(--headerBgColor)!important;
    color: var(--headerTxtColor)!important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: var(--header-height);
}


.header-version {
    flex-grow: 1; // Allow this element to take up available space
    text-align: right; // Align text to the right if needed
}
.outline-button , .outline-button:focus , .outline-button:active , .outline-button:hover {
    background-color: transparent!important;
    border-width: 0px;
    color: var(--defaultButtonColor);
}

.user-icon{
    width: 30px;
    height: 30px;
}


.dashboard-icon{
    width: 23px;
    height: 23px;
}


.ant-menu-title-content{
    font-weight: bold;    
}

.ant-menu-submenu{
    .ant-menu-title-content{
        font-weight: normal    
    }
    .ant-menu-submenu-title {
        .ant-menu-title-content{
            font-weight: bold;
        }
    }
}