@import 'partials/variables';

.trip-sheet-permissions-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:80px;
    margin-bottom: 5px;
}


.trip-sheet-permissions-root{
    background-color: var(--secondaryColor);
    .trip-sheet-permissions-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
    }
}

.trip-sheet-permission-agent-selector{
    min-width: 200px;
}

.form-agent{
    display: contents;
    .ant-form-item-explain-error{
        display: none;
    }
}

.add-action-btn,.add-action-btn:hover{
    color:  lightgreen;

}