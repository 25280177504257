@import 'partials/variables';

.spalsh-screen-div{
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.home-page-root{
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    padding-top: 20px;
    @media only screen and (max-width: 768px) {
        padding: 2vw;
        max-width: 100vw;
    }
    .home-page-form{
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 4vw;
            min-width: 90vw;
        }
    }
}

.schedule-details{
    padding-bottom: 20px;
}


.footer-block{
    display: flex;
    flex-direction: row;
    justify-content: center;
    .cancel-button-root{
        margin-right: 10px;
    }
}

.search-bus-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:40px;
    margin-bottom: 5px;
}

.header-root{
    margin-bottom: 5px;
    @media only screen and (min-width: 768px) {
        margin-bottom: 20px;
    }
}