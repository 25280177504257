@import 'partials/variables';

.notification-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
        max-width: 100vw;
    }
    padding: 20px;
    .notification-inner-div{
        background-color: white;
        border-radius: 6px;
        .bus-cancel-form-div{
            @media only screen and (max-width: 768px) {
                min-width: 90vw;
                padding: 2vw;
            }
        }
        padding: 20px;
    }
}

.notification-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:80px;
    margin-bottom: 5px;      
}