.manual-trip-page-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
        max-width: 100vw;
    }
    padding: 20px;
    .manual-trip-page-form{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 4vw;
            min-width: 90vw;
        }
        padding: 20px;
    }
}