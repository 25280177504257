/* Device Width--Resolution Settings */

/* Extra Small Screen (or) Phone */
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

/* Small Screen (or) Tablet */
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

/* Medium Screen (or) Desktop */
$screen-md:                  960px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

/* Large Screen (or) Wide Desktop */
$screen-lg:                  1280px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

/* So media queries don't overlap when required */
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$width-of-search-result-card : 1000px;


:root{
/*Standard colors*/

--white : #FFFFFF;
--black : #000000;

/* Site-wide color's settings */
--primaryColor : #001529;
--secondaryColor : #F4F6F9;
--defaultButtonColor : #FF6767;
--defaultButtonText : #FFFFFF;
--headerBgColor : #001529;
--headerTxtColor : #FFFFFF;
--dropDownMenuBgColor : #FFFFFF;
--dropDownMenuTxtColor : #000000;
--dropDownSelectedMenuBgColor : #001529;
--dropDownSelectedTxtColor : #FFFFFF;
}