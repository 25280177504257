@import 'partials/variables';

.temporary-changes-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:100px;
    margin-bottom: 5px;
}

.temporary-changes-page-root {
    padding: 20px;
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    .temporary-changes-page-inner-div {
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
    }

    .schedule-left-arrow,
    .schedule-right-arrow {
        border: none;
        background: white;
        box-shadow: none;
        color: var(--defaultButtonColor);

        &:hover, &:focus {
            background: white;
        }
    }

    .ant-tabs-nav {
        margin-bottom: 24px;
    }
} 

.temporary-changes-date-picker{
    border-width: 0px!important;
    margin-left: 4px;
    margin-right: 4px;
    &:hover, &:focus, &:active , &:focus-visible,&:focus-within,&:active-item,&:left{
        border-width: 0px!important;
        box-shadow: none!important;
    }
}

.temporary-changes-tabs{
    .ant-tabs-tab{
        margin-left: 2px;
        background-color:#E7F1FF!important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        min-width: 150px;
        text-align: center;
        justify-content: center;
    }
    .ant-tabs-tab:hover{
        background-color: #E7F1FF!important;
    }
    .ant-tabs-tab-active{
        margin-left: 2px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        min-width: 150px;
        text-align: center;
        justify-content: center;
        background-color: white!important;
    }
    .ant-tabs-tab-active:hover{
        background-color: white!important;
    }
}