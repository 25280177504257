

.discount-modal-root{
    z-index: 1000!important;
}

.add-button-content{
    z-index: 9!important;
}

.hide-popover{
    display: none;
}

.button-over-space{
    height: 50px;
    width: 50px;
}