@import 'partials/variables';

.service-access-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:60px;
    margin-bottom: 5px;
}

.title-service-access{
    padding-bottom: 10px;
}

.service-access-page-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .service-access-page-inner-div{
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
    }
}

.get-services-btn{
    @media only screen and (max-width: 450px) {
        margin-top: 10px;
    }
}