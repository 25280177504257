.user-action-page-root{
    align-content: center;
    padding: 10px;
    flex: 1;
}

.user-action-header{
    font-weight: bold;
}

.actions-container {
    margin-top: 20px;
    margin-left: 20px;
    .action-section {
        margin-bottom: 24px;
        .section-title {
            text-align: center;
            color: #333;
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: bold;
            @media (max-width: 768px) {
                font-size: 18px;
            }
        }
        .sub-section-title {
            font-weight: bold;
            margin-bottom: 16px;
            font-size: 12px;
            align-content: start;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
        .ant-row {
            margin-bottom: 8px;
        }
    }
}