.users-select {
    width: 100%;
    margin: 0px!important;
    .ant-select:not(.ant-select-customize-input) ,
    .ant-select-selector,.ant-select-selector:hover,.ant-select-selector:active,
    .ant-select-selector:focus{
        border: 0px solid white!important;
        box-shadow: none!important;
    }
    .ant-select-selection-item {
        background-color: white !important;
        box-shadow: none!important;
    }
    .ant-select-selector {
        border-color: #E7DBFF !important;
        box-shadow: none!important;

        &:focus,
        &:active {
            border-color: #E7DBFF !important;
            box-shadow: none!important;
        }
    }
}

.users-select-popup{
    .ant-select-item-option-selected{
        background-color: #E7DBFF !important;
    }
    .anticon-check{
        color: var(--defaultButtonColor) !important;
    }
}

.selected-users-container{
    margin-top: 10px;
}
.selected-user-tag{
    background-color: var(--defaultButtonColor) !important;
    border: 1px solid var(--defaultButtonColor) !important;
    margin-top: 5px;
    color: white !important;
    .close-icon-white,.ant-tag-close ,.ant-tag-close-icon{
        color: white !important; 
    }
}
.users-select-label {
    background-color: #E7DBFF;
    padding: 8px 12px;
}

