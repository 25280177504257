.site-extended-calendar {
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    
    .ant-picker-calendar {
        background: #fff;
    }
    
    .previous-calender-btn {
        padding: 0;
    }
}

.schedule-date-select {
    min-width: 200px;
}

.next-month-date {
    color: #d9d9d9;
}

.disable-clicking {
    cursor: not-allowed;
    
    &, & + * {
        pointer-events: none;
    }
}

td:has(.disable-clicking) {
    cursor: not-allowed;
}
