.select-schedule-button , .select-schedule-button:hover , .select-schedule-button:focus{
    width: 186px!important; 
    background: #E7F1FF;
    border-color: #E7F1FF;
    border-radius: 0px 0px 6px 0px;
    padding-bottom: 4px;
}

.select-edit-fare-block ,.select-edit-fare-block:hover ,.select-edit-fare-block:focus ,.select-edit-fare-block:active{
    background-color: transparent;
    border-color: #1057B9;
    border-width: 0px 0px 5px 0px;
}

.active-outline-button , .active-outline-button:hover , .active-outline-button:focus , .active-outline-button:active{
    background-color: transparent;
    border-color: #1057B9!important;
    border-width: 0px 0px 5px 0px;
}

.edit-fare-block{

}

.edit-fare-outline-button , .edit-fare-outline-button:hover , .edit-fare-outline-button:focus , .edit-fare-outline-button:active{
    color: #000000!important;
    background: #E7F1FF;
    border-color: #E7F1FF;
    border-radius: 0px;
}

.selected-edit-fare-outline-btn , .selected-edit-fare-outline-btn:hover , .selected-edit-fare-outline-btn:focus , .selected-edit-fare-outline-btn:active{
    color: #1057B9!important;
    background-color: transparent;
    border-color: #1057B9!important;
    border-width: 0px 0px 5px 0px;
    border-radius: 0px;
}

.calender-icon{
    width:30px;
    padding:4px;
}

.schedule-activate-button,.schedule-activate-button:hover,.schedule-activate-button:focus{
    background: #01B468;
    border-color: #01B468;
}

.schedule-block-button,.schedule-block-button:hover,.schedule-block-button:focus{
    background: #EA4025;
    border-color: #EA4025;
}

.extend-block-root{
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 1px solid #AFCFFC;
}

.schedule-date-select{
    width: 130px;
}

.site-extended-calendar {
    width: 266px;
    border: 1px solid #f0f0f0;
    
    .ant-picker-cell{
        padding: 2px;
    }

    .ant-picker-content th{
        font-weight: bold;
    }
    
    .previous-month-date{
        color: #FFFFFF;
        background-color: #FFFFFF;
    }

    .next-month-date{
        display: none;
    }
  
    .ant-picker-cell:not(.ant-picker-cell-in-view) {
        pointer-events: none;
        // display: none;
    }

    .ant-picker-cell {
        z-index: 1;
    }

    .current-date{
        height: 100%;
        width: 100%;
        display: flex;
        // justify-content: center;
        align-items: center;
        border-radius: 4px;
        z-index: 100;
    }

    .next-month-date{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
    }

    .schedule-date-selected{
        background-color: #FFA000;
        border-radius: 50%;
        height: 80%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
    }
  }


  .empty-div{
    display: none;
  }

  .blocked-schedule{
    background-color: #EA4025;
    border-color: #EA4025;
    color: #FFFFFF;
  }

  .disabled-api-schedule{
    background-color: #BF56FF;
    border-color: #BF56FF;
    color: #FFFFFF;
  }

  .active-date-underline{
    text-decoration-line: underline;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  .active-demand {
    border: 4px solid #6EEE11;
  }

  .previous-calender-btn , .previous-calender-btn:hover , .previous-calender-btn:active , .previous-calender-btn:focus {
    background-color: transparent;
    border-color: transparent;
    color: #1D2A80;
  }

  .normal-txt{
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #1D2A80;
  }

  .tabs-in-schedules {
    margin-right: 0px!important;
    .ant-tabs-tab {
        margin: 0px;
    }
    .ant-tabs-nav-wrap{
        display: flex;
        @media only screen and (min-width: 768px) {
            justify-content: end;
        }
        .ant-tabs-tab-btn :hover{
            color: #000000!important;
        }
    }
    .ant-tabs-ink-bar{
        background : transparent!important;
        height:0px;
    }
    .ant-tabs-tab-btn{
        padding:0px 5px 0px 0px;
    }
    .ant-tabs-tab:active,.ant-tabs-tab:focus,.ant-tabs-tab,.ant-tabs-tab:hover{
        color: #000000!important;
    }
}

.edit-fare-date-block{
    flex-direction: column;
}

.min-fare-in-calender{
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    color: #000000;
    padding: 2px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.cut-route-enabled{
    background: #FFFFFF;
    border: 3px solid #000000;
    border-radius: 4px;
}

.vehicle-info-container {
    padding: 20px;
}

.change-layout-container {
    padding: 24px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    .ant-checkbox-wrapper {
        font-size: 14px;
        margin-bottom: 16px;
    }

    .ant-picker {
        width: 100%;
        max-width: 400px;
    }
}