.via-cities-underline{
    border-bottom: 4px solid var(--defaultButtonColor)!important;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 0px!important;
}

.badge {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 5px; // Space between text and badge
}

.badge-origin {
    background-color: #4caf50; // Green for origin
    color: white;
}

.badge-destination {
    background-color: #f44336; // Red for destination
    color: white;
}