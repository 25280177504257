

.print-button{
    background-color: #0069D9!important;
    border-color: #0069D9!important;
}

@media screen {
	.printer-render {
		display: none;
	}
}

.scaled{
	// transform: scale(0.5, 1);
	zoom:0.5;
}

.shareButton{
	background-color: #46C256!important;
	border-color: #46C256!important;
	line-height: 40px!important;
}

.moveDivTOhidden{
	position: fixed;
	bottom: -10000px;
}

.shareDiv{
	padding: 10px;
}