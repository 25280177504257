.operator-config-page{
    margin: 10px;
    padding: 20px;
    .operatorSelect{
        width: 200px;
    }
    .blockAgentPortal{
        margin: 10px;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        width: 90vw;
    }

    .ant-collapse {
        background: var(--secondaryColor);
        .ant-collapse-item {
            background: white;
            margin-bottom: 10px;
            border-radius: 5px;
            .ant-collapse-header {
                font-weight: 500;
                font-size: 16px;
            }
            
            .ant-collapse-content {
                .blockAgentPortal {
                    padding: 16px;
                }
            }
        }
    }

    .ant-row {
        margin-bottom: 16px;
    }
}
