.changeBusSelect{
    min-width: 200px;
}

.changeBusBlock{
    // width: 100vw;
    justify-content: center;
    align-items: center;
    text-align: center;

    // align-content: center;
    // flex: 1;
    margin: 5px;
}

.selectBusTxt{

}

.layoutMapTable{
    th {
        text-align: left;
    }
}

.layout-section {
    padding: 16px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    
    .seat-container {
        margin-top: 16px;
        overflow-x: auto;
        
        // Ensure touch scrolling works smoothly on mobile
        -webkit-overflow-scrolling: touch;
    }
}

.changeBusSelect {
    margin-bottom: 24px;
}

@media (max-width: 768px) {
    .layout-section {
        margin-bottom: 16px;
    }
    
    .seat-container {
        min-height: 200px;
    }
}