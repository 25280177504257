.ant-checkbox-input:not(:checked) > .ant-checkbox-inner{
    content: '-';
}

.stop-collapse {
    .ant-collapse-content-box {
        padding: 0px!important;
    }
    .ant-collapse-header{
        display: flex;
    }
}

.new-timing-body{
    @media only screen and (max-width: 768px) {
        font-size: 10px;
    }
}

.createStopDiv{
    margin: 5px;
    .createStopBtn{
        height: 24px;
        font-size: 10px;
    }
}

.selectStop{
    @media only screen and (min-width: 768px) {
        width: 140px;
    }
    @media only screen and (max-width: 768px) {
        width: 100px;
        font-size: 11px;
    }
}

.createNewStopModal{
    background-color: #FFFFFF;
    padding: 0px;
}

.new-timing-root{

    @media only screen and (max-width: 768px) {
        font-size: 10px;
    }

    padding: 10px;
    border-top: 1px solid #d9d9d9;

    .dividerCol{
        padding: 0px;
        margin: 0px;
    }
    .heading-new-timing{
        font-weight: bold;
        color: black;
    }
    .draw-line{
        border-bottom: 1px solid #817D7D;
        margin: 5px;
    }

    .city-row{
        background: #EDEFFF;
        font-weight: bold;
    }

    .stop-row{
        display: flex;
        align-items: center;
        align-content: center;
        .description-row{
            align-content: center;
        }
    }

    .inactive-stop {
        text-decoration: line-through;
    }


    .timing-col{
        @media only screen and (min-width: 768px) {
            width:80px;
        }
    }

    .day-col{
        .dayInput{
            width:30px;
            margin-left: 5px ;
        }
    }

    .trip-close-col{
    }

    .edit-col{
    }

    .addStopBtn{
        height: 24px;
        @media only screen and (max-width: 768px) {
            font-size: 10px;
            padding: 4px;
        }    
    }
}

.errorInput{
    border-color: #ff7875;
    .ant-select-selector{
        border-color: #ff7875!important;
    }
}

.mobile-time-picker {
    input {
        font-size: 11px !important;
    }
    
    .ant-picker-suffix {
        font-size: 12px;
    }
}

.mobile-day-input {
    input {
        font-size: 11px !important;
    }


    .ant-input-number-handler-wrap {
        span {
            font-size: 12px;
        }
    }
}

// If you want to adjust the height of the input boxes on mobile as well
.mobile-time-picker, .mobile-day-input {
    &.ant-picker, &.ant-input-number {
        height: 24px !important;  // Adjust this value as needed
    }
}

@media screen and (max-width: 768px) {
  .new-timing-root {
    padding: 0 4px;
    
    .stop-row, .heading-new-timing {
      width: 100%;
      position: relative;
    }
  }

  .stop-row {
    font-size: 11px !important;
    line-height: 1.2;
    margin-bottom: 4px !important;
    
    // Stop name column
    .stop-name-col {
      .stop-city-col {
        font-size: 11px;
        text-overflow: ellipsis;
        max-width: 95%;
        padding-right: 2px;
      }

      .description-row {
        display: flex;
        align-items: center;
      }
    }

    // Time picker column
    .time-col-in-stops {
      padding: 0 2px;
      
      .ant-time-picker, .mobile-time-picker {
        width: 80px !important;
        .ant-picker {
          height: 22px !important;
          padding: 0 4px !important;
        }

        .ant-picker-input {
          height: 22px !important;
          
          > input {
            font-size: 10px !important;
            padding: 0 !important;
            height: 22px !important;
            line-height: 22px !important;
          }

          .ant-picker-suffix {
            font-size: 10px;
            margin-left: 2px;
            
            .anticon {
              font-size: 10px;
            }
          }
        }
      }
    }

    // Day input column
    .day-col {
      padding: 0 2px;
      
      .mobile-day-input {
        width: 32px !important;
        
        .ant-input-number-input {
          font-size: 11px !important;
          padding: 0 !important;
          text-align: center;
        }
      }

      .day-display {
        text-align: center;
      }
    }

    // Action column (Trip close + Edit)
    .action-col {
      padding-left: 2px;
      

      .trip-close-checkbox {
        .ant-checkbox {
          transform: scale(0.7);
          margin: 0;
        }

        .tick-icon, .tick-icon-placeholder {
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .edit-col {
        padding: 0 !important;
        min-width: 20px !important;
        height: 20px;
        
        .anticon {
          font-size: 13px;
        }
      }
    }

    // Boarding/Drop badges
    span[style*="backgroundColor"] {
      padding: 0 2px !important;
      font-size: 9px !important;
      margin-right: 1px !important;
      line-height: 1.2;
    }
  }

  .heading-new-timing {
    font-size: 11px !important;
    font-weight: 500;
    margin-bottom: 4px;
    
    > div {
      padding: 0 2px;
    }
  }

  // Collapse panel adjustments
  .ant-collapse-content-box {
    padding: 4px 0 !important;
  }

  .ant-collapse-header {
    padding: 8px 4px !important;
    font-size: 12px;
  }

  // Checkbox in collapse header
  .ant-collapse-expand-icon {
    .marginRight {
      font-size: 16px !important;
    }
  }

  // Style for time picker popup in mobile
  .mobile-time-picker-popup {
    .ant-picker-panel-container {
      .ant-picker-panel {
        .ant-picker-content {
          width: 200px !important;
          
          th, td {
            height: 24px !important;
            
            .ant-picker-time-panel-cell-inner {
              font-size: 11px !important;
              padding: 0 4px !important;
              height: 24px !important;
              line-height: 24px !important;
            }
          }
        }
      }
    }

    .ant-picker-header {
      padding: 4px 8px !important;
      
      .ant-picker-header-view {
        font-size: 11px !important;
      }
    }

    .ant-picker-time-panel-column {
      > li {
        height: 24px !important;
        line-height: 24px !important;
        
        .ant-picker-time-panel-cell-inner {
          font-size: 11px !important;
          padding: 0 4px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {


.mobile-phone-row {
    margin-top: 8px;
    padding-bottom: 8px;
}

.stop-row {
    .stop-name-col {
        .selectStop {
            width: 100%;
            
            @media (max-width: 768px) {
                max-width: 120px;
            }
        }
    }
}

.new-timing-tab-block{
    border-top: 1px solid #d9d9d9;
    .stop-row{
        margin-top: 5px;
    }
  }
}
.stop-row{
  margin-bottom: 4px !important;
}

.action-wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-direction: row;
  display: flex;
}
