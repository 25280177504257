
.seat-price-root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seat-price-btn{
    background-color: #00834C;
    border-color: #00834C;
    color: white!important;

}

.seat-price-btn:hover{
    background-color: #00834C;
    border-color: #00834C;
    color: white!important;
}

.seat-price-btn:focus{
    background-color: #00834C;
    border-color: #00834C;
    color: white!important;
}   

.seat-price-btn:active{
    background-color: #00834C;
    border-color: #00834C;
    color: white!important;
}           


