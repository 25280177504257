.arrow-icon {
    float: right;
    transition: transform 0.3s ease;
}

details[open] .arrow-icon {
    transform: rotate(90deg);
}

.stops-header {
    font-weight: bold;
    color: #7B7878;
}

.location-item {
    .value-tag-container {
        background-color: #F9FBFF;
        margin: 4px 0;
        border-radius: 8px;
        padding: 2px 8px;
        width: 100%;

        .ant-row {
            width: 100%;
        }
    }
}

  .stops-removed-calendar-container {
    border-radius: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    .ant-picker-content{
      table-layout: auto;
    }

    .ant-picker-body{
      background-color: #F4F6F9;
    }

    .ant-picker-content th{
      font-weight: bold;
    }
    .ant-picker-calendar-mini .ant-picker-content{
        height: 134px;    
    }

  
    .ant-picker-cell:not(.ant-picker-cell-in-view) {
        pointer-events: none;
    }
    
    .not-removed-date{
      background-color: white;
    }
    .removed-date{
      background-color: #FF6767;
    }
    .added-date{
      background-color: #67C23A;
    }
    .time-changes-date{
      background-color: #FF9800;
    }
  }

.custom-calendar {
  .ant-picker-calendar-date {
    height: 24px !important;
    padding: 0 !important;
    width: 24px !important;
  }
  
  .ant-picker-cell {
    padding: 2px !important;
  }
}


.expanded-calendar-icon{
  border-bottom: 4px solid var(--defaultButtonColor);
  color: var(--defaultButtonColor);
  border-radius: 10%;
}

.collapsed-calendar-icon{
  border-bottom: 4px solid transparent;
  border-radius: 10%;
}

.city-panel{
  .ant-collapse-header{
    padding: 0px 0px !important;
  }
}

.no-data {
  text-align: center;
  padding: 20px;
  color: #999;
}