div.timeline_container div.ant-timeline-item-label {
    width: calc(10% - 12px) !important;
  }
  
  div.timeline_container div.ant-timeline-item-content {
    left: calc(11% - 4px) !important;
    width: calc(89% - 4px) !important;
  }
  
  div.timeline_container div.ant-timeline-item-tail,
  div.ant-timeline-item-head {
    left: 11% !important;
  }