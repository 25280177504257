
@import 'partials/variables';

.vehicle-management-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .vehicle-management-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
        .vehicle-management-form{
            @media only screen and (max-width: 768px) {
                max-width: 80vw;
            }
        }
    }
}
