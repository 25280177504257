

.trip-time-vechile-edit-block{
    width: 250px;
}

.searchResultHeaderTxt{
    font-weight: bold;
    @media only screen and (max-width: 768px) {
        margin-bottom: 5px;
    }
}

.expandend-block{
    margin: 10px;
    border-top: 1px solid #d9d9d9;
    .expand-root{
        margin: 5px;
        @media only screen and (min-width: 768px) {
            margin: 10px;
        }
    }
}

.search-item-fourth-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
}

.scrollable-block-root{
    margin: 0px;
}

.search-desktop-tabs{
    .ant-tabs-nav-list{
        display: flex;
        justify-content: flex-end;
        flex: auto;
    }
}

.search-mobile-tabs{
    .ant-tabs-nav-wrap{
        justify-content: flex-end;
    }
}

.search-item-select-seats-row{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.search-list-root {
    .ant-list-header, .ant-list-header {
        padding-top: 10px;
        padding-bottom: 0px;
    }
}