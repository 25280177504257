.colour-config-page{
    margin: 10px;
    background-color: white;
    .operatorSelect{
        width: 200px;
    }

    .ant-form-item-control {
        width: 20%;
    }
    .ant-form-item-control-input {
        width: 200px;
    }
    #colorConfig {
        margin: 10px;
        padding: 10px;
    }
}
