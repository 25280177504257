

.bus-cancellation-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:100px;
}

.bus-cancel-btn{
    min-width: 180px;
}

.bus-cancellation-modal{
    .ant-modal-content {
        width: fit-content;
        min-width: 90vw;
    }
}