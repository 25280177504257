
.google-maps-root{
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px); 
    -ms-filter: blur(0px);
    filter: blur(0px);
    pointer-events:all;
    @media only screen and (max-width: 968px) {
        padding-top: 10px;
    }
}

.marker-label{
    margin-bottom: 20px;
    margin-right: 8px;
    font-weight: bold;
}

.map-top-header{
    padding: 10px;
    font-weight: 700;
    font-family: 20px;
    line-height: 23px;
}

.map-footer-header{
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.set-location , .set-location:hover , .set-location:active , .set-location:focus{
    background-color: #2BB193;
    border: #2BB193;
}
