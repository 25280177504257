/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/


  /* Seat Layout styles - Note: Keep it at end of the file */

  /* class to render seater type seats */

  :root {
    --ladies-seat:#ff82a5;
    --gents-seat:#1D2A80;
    --avalible-seat:#5EA60F;
    --self-blocked:#4A90E2;
    --block-others:#E24A4A;
    --booked-seat:#1CAD72;
    --selected:#F8E71C;
    --phone-block:purple;
  }

  @keyframes flash-ladies-booked {
    0% { background-color: white; }
    50% { background-color: var(--ladies-seat); }
    100% { background-color: white; }
  }

  @keyframes flash-self-blocked {
    0% { background-color: white; }
    50% { background-color: var(--self-blocked); }
    100% { background-color: white; }
  }

  @keyframes flash-phone-blocked {
    0% { background-color: white; }
    50% { background-color: var(--phone-block); }
    100% { background-color: white; }
  }
  
  @keyframes flash-block-others {
    0% { background-color: white; }
    50% { background-color: var(--block-others); }
    100% { background-color: white; }
  }
  
  @keyframes flash-booked {
    0% { background-color: white; }
    50% { background-color: var(--booked-seat); }
    100% { background-color: white; }
  }
  
  

  .ladies-seat > .ladies-indicator{
    background-color: var(--ladies-seat);
  }

  .gents-seat > .gents-indicator{
    background-color: var(--gents-seat);
  }

  .seater {
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 30px;
    border-radius: 2px;
    border: 1px solid var(--avalible-seat);
    background-color: white;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }

  .step-two-city{
    min-width:200px;
  }

  .step-two-time{
    min-width:200px;
  }

  .step-two-origin{
    min-width:200px;
  }

  .step-two-destination{
    min-width:200px;
  }

  .step-two-next-day{
    min-width:200px;
  }

  .seat-number {
    position: relative;
    font-size: 0.78em;
    color: #000000;
    text-align: center;
  }

  /* class to render sleeper type seats */
  .sleeper {
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 64px;
    border-radius: 2px;
    border: 1px solid var(--avalible-seat);
    background-color: white;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
    // transform: rotate(270deg); /* Equal to rotateZ(45deg) */
  }


  /* class to render booked seats */
  .seater.booked, .sleeper.booked {
    border: 1px solid var(--booked-seat);
    background-color: var(--booked-seat);
    /* pointer-events:none; */
  }

  .flashing-seat.booked {
    animation: flash-booked 1s infinite;
  }

  .opacity-seat{
    opacity: 0.5;
  }

  .ladies-booked{
    border: 1px solid var(--ladies-seat);
    background-color: var(--ladies-seat);
  }

  .flashing-seat.ladies-booked {
    animation: flash-ladies-booked 1s infinite;
  }
    /* class to render blocked seats */
    .seater.self-blocked, .sleeper.self-blocked {
      border: 1px solid var(--self-blocked);
      background-color: var(--self-blocked);
    }

    .flashing-seat.self-blocked {
      animation: flash-self-blocked 1s infinite;
    }

    .phone-blocked {
      border: 1px solid var(--phone-block);
      background-color: var(--phone-block);
    }

    .flashing-seat.phone-blocked {
      animation: flash-phone-blocked 1s infinite;
    }

    .seater.others-blocked, .sleeper.others-blocked {
      border: 1px solid var(--block-others);
      background-color: var(--block-others);
    }

    .flashing-seat.others-blocked {
      animation: flash-block-others 1s infinite;
    }
    
  .seat-line {
    background-color: #dfe2ed;
  }

  /* Class to render seat gap */
  .seatGap{
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 30px;
    border-radius: 2px;
    background-color: white;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }

  .sleeperGap{
    width: 30px;
    @media only screen and (max-width: 768px) {
      width:25px;
    }
    height: 64px;
    border-radius: 2px;
    background-color: white;
    display: flex;
    margin: 4px 4px;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }

  .seater.ladies, .sleeper.ladies {
    border: 1px solid var(--ladies-seat);
  }

  .seater.ladies > .seat-line , .sleeper.ladies > .seat-line {
    border: 1px solid var(--ladies-seat);
    background-color: var(--ladies-seat);
  }

  .seater.gents, .sleeper.gents {
    border: 1px solid var(--gents-seat);
  }

  .seater.gents > .seat-line , .sleeper.gents > .seat-line{
    border: 1px solid var(--gents-seat);
    background-color: var(--gents-seat);
  }
  /* class used for seat design only. no functional */
  .seat-line {
    width: 17px;
    height: 3px;
    border-radius: 2px;
    background-color: var(--avalible-seat);
    margin: 5px auto;
    align-self: flex-end;
    display: inline-block
  }

  /* container for row's with seater and sleeper class */
  .seats-container {
    display: flex;
    flex-direction: row;
  }

  .seats-column {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    width:35px;
     @media only screen and (max-width: 768px) {
      width:30px;
    }
    /* margin: 4px 4px; */
  }

  .layout-container {
    display: inline-flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 8px;
    border: 1px solid #dfe2ed;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .seats-column-empty {
    min-width: 20px;
  }

  .last-row {
    display: flex;
    flex-direction: row;
  }

  .sleeper-last-row {
    width: 30px;
    min-height: 30px;
    border-radius: 2px;
    border: 1px solid #dfe2ed;
    background-color: white;
    margin: 2px 2px;
    display: flex;
    flex: 1;
  }

  .seat-line-last-row {
    width: 3px;
    height: 17px;
    border-radius: 2px;
    background-color: #dfe2ed;
    margin: auto 5px;
    align-self: flex-end;
    display: inline-block
  }

  /* class to display selected elected seat's */
  .selected {
    border: 1px solid var(--avalible-seat);
    background-color: var(--selected)!important;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ladies.selected {
    border: 1px solid var(--ladies-seat);
    background-color: var(--selected);
    color: white;
  }

  .gents.selected {
    border: 1px solid var(--gents-seat);
    background-color: var(--selected);
    color: white;
  }

  .seat-gender{
    font-size: 8px;
    height: 10px;
    text-align: center;
  }

  /* class to display selected elected seat's inside line */
  .selected .seat-line {
    background-color:var(--avalible-seat);
  }

  /* Female seat indicator */
  .female-seat .seat-line {
    background-color: var(--ladies-seat);
  }

  /* Upper and Lower deck class */
  .deck-info {
    color: #6e7282;
    font-size: 15px;
    font-weight: 400;
    margin: 10px 3px;
  }

  .seat-layout-parent-container {
    display: flex;
    justify-content: center;
    margin-left: 10px;
    @media only screen and (max-width: 768px) {
      // min-width: 100vw;
      margin-left: 5px;
    }
  }

  .seat-indicator-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .indicator-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin-right: 20px;
  }

  .seat-legend{
    background-color: var(--defaultButtonColor);
  }

  .seat-legend-root{
    min-height: 90px;
  }

  .available-seat-legend,
  .unavailable-seat-legend,
  .booked-seat-legend,
  .ladies-seat-legend,
  .self-blocked-legend,
  .blocked-others-legend,
  .phone-blocked-legend,
  .gents-seat-legend ,
  .ota-legend,
  .seat-legend
  {
    display: flex;
    width: 30px;
    min-height: 60px;
    border-radius: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 5px;
    // align-items: center;
  }

  .available-seat {
    border: 1px solid var(--avalible-seat);
    background-color: white;
  }

  .ladies-seat {
    border: 1px solid var(--ladies-seat);
    background-color:var(--ladies-seat);
  }

  .gents-seat {
    border: 1px solid var(--gents-seat);
    background-color:var(--gents-seat);
  }

  .unavailable-seat-legend{
    background-color: #DFE2ED;
  }

  .self-blocked-legend{
    background-color: var(--self-blocked);
  }

  .blocked-others-legend{
    background-color: var(--block-others);
  }

  .ota-legend{
    background-color: #964B00;
  }

  .phone-blocked-legend{
    background-color: var(--phone-block);
  }

  .ladies-seat-legend{
    border: 1px solid var(--ladies-seat);
  }

  .gents-seat-legend{
    border: 1px solid var(--gents-seat);
  }

  .indicator-description{
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
  }

  .seat-colors-txt{
    padding-right: 5px;
  }

  .passengerCountBlock{
    width: 11px;
    height: 11px;
    background: #000000;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 0px;
    .passengerCount{
      text-align: center;
      font-size: 8px;
      color: #FFFFFF;
    }
  }

  .passengerDetailsBlock{
    padding: 5px;
    span{
      color: #FFFFFF!important;
      font-weight: 600;
    }
    color: #FFFFFF!important;
  }

  .ota-blocked{
    background-color: #964B00!important;
    border-color: #964B00!important;
  }

  .seat-fare{
    font-size: 8px;
    color: #000000;
    text-align: center;
  }

  .editing-row {
    background-color: #f0f7ff;
  }

  .action-icon {
    font-size: 16px;
    cursor: pointer;
    &:hover {
        color: #1890ff;
    }
  }

  .dragging {
    opacity: 0.5;
  }

  .can-drop {
    background-color: rgba(94, 166, 15, 0.1);
    &.is-over {
        background-color: rgba(94, 166, 15, 0.3);
    }
  }

  .seat-drag-handle {
    cursor: move;
  }

  .column-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .deck-label {
        font-size: 12px;
        font-weight: bold;
        color: #666;
        margin-bottom: 8px;
        padding: 2px 6px;
        border-radius: 4px;
        background-color: #f0f0f0;
    }
}