@import 'partials/variables';

.schedule-page-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .schedule-page-inner-div{
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
    }
    .schedule-right-arrow , .schedule-left-arrow{
        background-color: #FFFFFF;
        color: #000000;
        border-color: #d9d9d9;
    }
}

.title-schedule{
    padding-bottom: 10px;
}

.schedule-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:40px;
    margin-bottom: 5px;
}

.schedule-destination-block{
    width: 250px!important;
}


.schedule-origin-block{
    width: 250px !important;
}

.filter-button{
    top:20px;
}

.filter-icon{
    font-size: 40px;
    color: var(--defaultButtonColor);
}

.schedule-filter-form{
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.schedule-list-root{
    margin: 5px;   
    @media only screen and (max-width: 768px) {
        margin: 2px;
    }
}

.schedule-list{
    background-color: white;
    border-radius: 7px;
    padding: 10px 0px 0px 10px;
    margin: 5px;   
    @media only screen and (max-width: 768px) {
        padding: 5px 0px 0px 5px;
        margin: 5px;   
    }
}

.inactive-schedule{
    background-color: rgb(216, 214, 214);
}

.schedule-tabs-block{
    padding-top: 10px;
}

.via-block{
    padding-left: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
}

.description-block{
    padding-left: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    color: #000000;
    @media only screen and (max-width: 768px) {
        padding-left: 5px;
    }
}