/**
* use component name as prefix and before class name and separate it using '-'
* Example ".my-component-classname"
*/

@import "../../styles/partials/variables";

.scrollable-calendar {
  display: flex;
  justify-content: center;
  @media screen and (min-width: $screen-sm) {

  }
  @media screen and (max-width: $screen-sm) {
    margin-bottom: 10px;
  }

  .scrollable-date {
    display: flex;
    height: 55px;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width:150px;
    margin: 2px;
    border-radius:8px;
    @media screen and (max-width: $screen-sm) {
      width: 100px;
    }
    background-color: #FFFFFF;
    // box-shadow: 0px 7px 4px #C0BFBF , 0px -1px 4px #C0BFBF;    
    span {
      color: #7B7878;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .active {
    // height: 58px;
    // box-shadow: 0px 7px 4px #C0BFBF , 0px -1px 4px #C0BFBF;    
    z-index: 1;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid  var(--defaultButtonColor)!important;
    span {
      font-weight: 700;
      color: var(--defaultButtonColor);
    }
  }

  .scrollable-date-first {
    border-radius: 8px 8px 8px 8px;
  }

  .scrollable-date-last {
    border-radius: 8px 8px 8px 8px;
  }

  @media screen and (max-width: $screen-xs){
    .scrollable-date-first, .scrollable-date-last {
      display: none;
    }

    .scrollable-date-mobile-first {
    }

    .scrollable-date-mobile-last {
    }
  }
}

.scroll-calender-left-button{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 1;
  color: var(--defaultButtonColor);
  background: #FFFFFF!important;
  min-height: 24px!important;
  min-width: 24px!important;
  max-height: 24px!important;
  max-width: 24px!important;
  margin: -10px;  
}

.scroll-icon-left{
  color: var(--defaultButtonColor)!important;
  font-size: 25px;
}


.scroll-calender-right-button{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 1;
  color: var(--defaultButtonColor);
  background: #FFFFFF!important;
  min-height: 24px!important;
  min-width: 24px!important;
  max-height: 24px!important;
  max-width: 24px!important;
  margin: -10px;
}