@import 'partials/variables';

.trip-create-success-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .trip-create-success-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
        .circle-block{
            display: flex;
            justify-content: center;
        }
        .text-block{
            display: flex;
            justify-content: center;
        }
    }
}
