

.add-subtract-block{
    flex-direction: row;
    display: flex;
}

.input-min{
    margin-left: 5px;
    margin-right: 5px;
    width:100px;
}

.footer-adjust-time-pop-up{
    display: flex;
    justify-content: space-around;
}

.from-to-block{
    display: flex;
    flex-direction: column;
}

.from-block{
    display: flex;
    flex-direction: row;
}

.to-block{
    display: flex;
    flex-direction: row;
}

.root-adjut-time-content{
    display: flex;
    flex-direction: column;
}

.from-to-block-text{
    min-width:105px;
    text-align: end;
}