.cities-removed-tab {
    .ant-collapse {
        background: transparent;
    }

    .no-data {
        text-align: center;
        padding: 20px;
        color: #999;
    }

    .cities-removed-details {
        padding: 0 20px;

        .cities-header {
            font-weight: bold;
            padding: 10px 0;
            border-bottom: 1px solid #f0f0f0;
        }

        .city-item {
            padding: 10px 0;
            border-bottom: 1px solid #f0f0f0;

            &:last-child {
                border-bottom: none;
            }
        }

        .calendar-container {
            margin-top: 15px;
            border: 1px solid #f0f0f0;
            border-radius: 4px;
            padding: 10px;
        }
    }

    .removed-date {
        background-color: #ff4d4f;
        color: white;
    }

    .not-removed-date {
        color: rgba(0, 0, 0, 0.85);
    }

    .expanded-calendar-icon {
        transition: transform 0.3s;
    }

    .collapsed-calendar-icon {
        transition: transform 0.3s;
    }
} 

.cities-removed-calendar-container{
    border: 0px solid #f0f0f0;
}