
.calendar-color-txt{
    color: var(--defaultButtonColor);
}

.date-legend{
    border-radius: 4px;
    width: 33px;
    height: 33px;
}

.blocked-date-legend{
    background: #EA4025;
}

.selected-date-legend{
    background: #FFA000;
}

.high-demand-date-legend{
    background: #FFFFFF;
    border: 3px solid #6EEE11;
    border-radius: 4px;
}

.confirm-tickets-count-block{
    width: 158px;
    height: 24px;
    background: linear-gradient(270deg, #116EEE 0%, #FFFFFF 100%);
}