

.site-calendar-customize-header-wrapper {
    width: 318px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    
  .ant-picker-content th{
    font-weight: bold;
  }
  .ant-picker-calendar-mini .ant-picker-content{
      height: 134px;    
  }

  .previous-month-date{
        color: #FFFFFF;
        background-color: #FFFFFF;
  }

  .next-month-date{
    display: none;
  }
  
  .ant-picker-cell:not(.ant-picker-cell-in-view) {
        pointer-events: none;
    }
    
    .current-date{
      // max-width: 25px;
      margin-left: 12px;
      margin-right: 12px;
      height: 15px;
      font-size: 12px;
    }
  }

  .active-status{
    border-bottom: 4px solid #2BB193;
  }

  .blocked-status{
    border-bottom: 4px solid #EA4025;
  }

  .not-active-status{
    color: #BBBBBB;
  }

  .disable-api-status{
    border-bottom: 4px solid #BF56FF;
  }

