@import 'partials/variables';

.standard-stops-root{
    background-color: var(--secondaryColor);
    @media only screen and (max-width: 768px) {
        padding: 2vw;
    }
    padding: 20px;
    .standard-stops-inner-div{
        background-color: white;
        border-radius: 6px;
        @media only screen and (max-width: 768px) {
            padding: 2vw;
        }
        padding: 20px;
    }
}


.standard-stops-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:80px;
    margin-bottom: 5px;
}
