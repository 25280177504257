
.select-seat-btn,.select-seat-btn:hover , .select-seat-btn:focus{
    border-top-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    border-bottom-right-radius: 6px!important;
    white-space: normal!important;
    word-wrap: break-word!important;
    min-height: 40px;
    height: auto!important;
}

.open-select-seat-btn , .open-select-seat-btn:hover , .open-select-seat-btn:focus{
    border-width: 0px;
    white-space: normal!important;
    word-wrap: break-word!important;
    min-height: 40px;
    height: auto!important;
    border-color: #FFFFFF!important;
}

.origin-block{
    margin-right: 5px;
}

.destination-block{
    margin-left: 5px;
}

.description-row{
    // flex-wrap: wrap;
}

.list-width{
    max-width: 300px;
}

.tabs-block{
}
.tabs-in-search {
    margin-right: 0px!important;
    .ant-tabs-nav{
        // float: right;
    }
    .ant-tabs-nav-list{
        // right: 50px;
    }
    .ant-tabs-nav-wrap{
        display: flex;
        @media only screen and (min-width: 768px) {
            justify-content: end;
        }
        margin-right: 10px;
    }
    .ant-tabs-tab:active,.ant-tabs-tab:focus,.ant-tabs-tab,.ant-tabs-tab:hover{
        color: #000000!important;
    }
}
