.schedule-container {
    .ant-steps-item-title{
        display: block!important;
        padding: 0!important;
        font-weight: 600!important;
    }
    .step2-header{
        // these is for step icon width plus right margin
        margin-left: 40px;
    }
}

.city-container {

}

.city-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.city-controls, .stop-controls {
    display: flex;
    gap: 10px;
    align-items: center;
}

.stop-item {

}

.stops-container {

}

.clone-schedule-divider{
    background-color: #E1E1E1;
}
