

.stop-name-details{
    display: flex;
    flex-direction: row;
    .stop-name-txt{
        padding-right: 10px;
    }
    .stop-more-icon{
        align-items: center;
        justify-content: center;
        align-content: center;
        padding-top: 5px;
    }
}

.pophover-button-block{
    display: flex;
    .edit-button-stop-name{
        margin: 5px;
    }
    .delete-button-stop-name{
        margin: 5px;
    }
}
