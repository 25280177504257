
@import '../../styles/partials/variables';

.search-item-root-div:hover{
    box-shadow: 0px 1px 1px 1px #c5c5c5;
    cursor: pointer;
}

.search-item-root-div{
    background-color: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 10px;
    
    @media only screen and (min-width: 768px) {
        width: $width-of-search-result-card;
    }

    .search-item-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 5px;
        padding-top: 5px;
        @media only screen and (min-width: 768px) {
            padding-left: 10px;
        }

    }
    .search-item-second-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;   
        padding-left: 5px;
        // align-items: center;
        @media only screen and (min-width: 768px) {
            padding-left: 10px;
            margin-bottom: -5px;
        }
    }
}

.origin-destionation-div{
    display: flex;
    flex-direction: row;  
    @media only screen and (min-width: 768px) {
       width: 300px;
    }  
}

.select-seats-div{
    @media only screen and (min-width: 768px) {
        // margin-top: -45px;
        // display: flex;
        // justify-content: flex-end;
    }
}
.select-seats-button{
    
}

.select-bus-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:100px;
}


.seat-block{
    @media only screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
    }
}

.passenger-block{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}

.seatNumberBlock{
    width:100px;
}

.passengerNameBlock{
    width: 150px;
}

.fareBlock{
    width: 100px;
}

.passenger-form-underline{
    border-top: 4px solid var(--defaultButtonColor);
    width:140px;
}