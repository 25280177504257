.services-list-container {
    // margin: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .select-all-checkbox {
        margin-bottom: 16px;
    }

    .cities-collapse {
        margin-bottom: 20px;
        background-color: white;
        .city-panel {
            border: 0px solid white!important;
            .ant-collapse-header{
                padding: 2px!important;
            }
        }
        
    }

    .schedule-item {
        width: 100%;
        padding: 2px;
    }

    .action-buttons {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .city-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 200px;
        .collapse-arrow{
            margin-left: auto;
            margin-right: 8px; 
            font-weight: bold;
            color: var(--defaultButtonColor);
        }
    }
} 

